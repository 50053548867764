import React from 'react';
import Icon from '@ant-design/icons';
import { useThemeSwitcher } from 'react-css-theme-switcher';

const TravelerD20Svg = () => {
    const { currentTheme } = useThemeSwitcher();

    return (
        <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="810"
             height="1em" width="1em">
            <defs>
                <style type="text/css"></style>
            </defs>
            <path
                fill={currentTheme === 'light' ? '#ff4d4f' : '#a61d24'}
                d="M245.490241 430.114422L34.392979 741.890379c-6.15992 9.99987 0.199997 22.999702 11.859846 24.279685l416.514598 44.139427-217.277182-380.195069zM46.812818 630.851819L197.390865 386.154992 44.112853 294.196185c-5.339931-3.199959-12.119843 0.639992-12.119843 6.859911v325.615777c0 8.059895 10.579863 11.059857 14.819808 4.179946zM68.492537 847.189013l388.794957 175.317726c10.599863 4.899936 22.699706-2.859963 22.699706-14.519811v-131.338297l-407.09472-44.599422c-8.899885-0.999987-12.459838 11.179855-4.399943 15.139804z m162.437893-515.553314L390.788357 45.759407c8.679887-14.119817-7.179907-30.499604-21.559721-22.279712L67.612548 220.697138c-4.939936 3.239958-4.779938 10.519864 0.259997 13.559824l163.057885 97.378737zM511.986785 351.995435h218.417167L539.246432 15.239802C532.986513 5.079934 522.486649 0 511.986785 0s-20.999728 5.079934-27.259646 15.239802L293.569618 351.995435H511.986785z m467.873932-57.79925l-153.278012 91.978807 150.578047 244.696826c4.219945 6.879911 14.819808 3.87995 14.819808-4.199945V301.056096c0-6.219919-6.779912-10.05987-12.119843-6.859911z m-186.817577 37.439514l163.057885-97.398737c5.059934-3.039961 5.199933-10.319866 0.259997-13.559824l-301.616088-197.197443c-14.379814-8.219893-30.239608 8.159894-21.559721 22.279712l159.857927 285.876292z m158.037951 500.41351L543.98637 876.628631v131.338297c0 11.679849 12.099843 19.419748 22.699706 14.519812l388.794958-175.317727c8.059895-3.939949 4.499942-16.119791-4.399943-15.119804z m-172.597762-401.934787l-217.257182 380.195069 416.514598-44.139427c11.659849-1.299983 18.019766-14.279815 11.859846-24.279685L778.483329 430.114422zM511.986785 415.994605H311.12939L511.986785 767.490047 712.84418 415.994605H511.986785z"
                p-id="811"></path>
        </svg>
    );
};

const TravelerD20 = (props: any) => {
    return <Icon component={TravelerD20Svg} {...props} />
}

export default TravelerD20;